@tailwind base;
@tailwind components;
@tailwind utilities;

/*
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*/

@font-face {
  font-family: "SweetSansProRegular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/SweetSansProRegular.otf") format("opentype");
}

@font-face {
  font-family: "SweetSansProExLight";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/SweetSansProExLight.otf") format("opentype");
}

@font-face {
  font-family: "TrajanPro";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/TrajanPro-Regular.ttf") format("truetype");
}

body {
  @apply bg-white;
  @apply font-body;
  @apply selection:bg-t-skin selection:text-t-black;
  @apply text-t-black/70;
}

/*******************************************************************************
* intro
*******************************************************************************/

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shine {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.animate-slideUp {
  animation: slideUp 1.5s ease-out forwards;
}

.animate-slideUpDelay {
  animation: slideUp 2s ease-out forwards;
}

.shine-effect {
  position: relative;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 3s linear infinite;
  display: inline-block;
}

.shine-effect::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background-size: 200% auto;
  animation: shine 3s linear infinite;
}

/*******************************************************************************
* headings
*******************************************************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply !leading-tight sm:!leading-tight md:!leading-tight lg:!leading-tight xl:!leading-tight 2xl:!leading-tight;
  @apply font-heading;
  @apply tracking-normal;
}

.s-h1 {
  @apply font-medium;
  @apply text-xl md:text-2xl xl:text-3xl;
  @apply tracking-wide;
  @apply uppercase;
}

.s-h2 {
  @apply font-medium;
  @apply md:text-lg xl:text-xl;
}

.s-h3 {
  @apply font-semibold;
  @apply xl:text-lg;
}

.s-h4 {
  @apply font-medium;
}

/*******************************************************************************
* typography
*******************************************************************************/

a {
  @apply text-t-red hover:text-t-beige;
  @apply transition-colors duration-300;
}

/* TODO: check if used, remove if not needed */
a.border-effect {
  @apply after:-bottom-2;
  @apply after:absolute;
  @apply after:bg-cartier-red;
  @apply after:duration-300;
  @apply after:h-[2px];
  @apply after:left-0;
  @apply after:transition-all;
  @apply after:w-full;
  @apply hover:after:w-0;
  @apply relative;
}

.s-text {
  @apply !leading-snug sm:!leading-snug md:!leading-snug lg:!leading-snug xl:!leading-snug 2xl:!leading-snug;
  @apply tracking-wide;
}

.s-list {
  @apply list-disc;
  @apply ml-4 sm:ml-8;
}

/*******************************************************************************
* border with hover effect
*******************************************************************************/

.border-hover {
  @apply relative;
  @apply font-bold;
  @apply outline-none;
  @apply px-4 py-3 lg:px-5 lg:py-4;
  /* @apply hover:scale-105; */
  /* @apply transition-transform duration-500; */
  box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.25);
}

.border-hover::before,
.border-hover::after {
  @apply absolute;
  /* @apply border border-black/5; */
  /* @apply border-transparent; */
  @apply bottom-0 right-0;
  @apply box-border;
  @apply content-[""];
  @apply pointer-events-none;
  @apply size-0;
}

.border-hover::before {
  @apply border-b;
  @apply border-l;
}

.border-hover::after {
  @apply border-r;
  @apply border-t;
}

.border-hover:hover::before,
.border-hover:hover::after {
  @apply border-t-gray;
  @apply size-full;

  transition:
    border-color 0s,
    width 0.3s,
    height 0.3s;
}

.border-hover:hover::before {
  transition-delay: 0s, 0s, 0.3s;
}

.border-hover:hover::after {
  transition-delay: 0s, 0.3s, 0s;
}

/*******************************************************************************
* scrollbar
*******************************************************************************/

/* width */
::-webkit-scrollbar {
  @apply w-2;
}

/* track */
::-webkit-scrollbar-track {
  @apply bg-t-skin;
}

/* handle */
::-webkit-scrollbar-thumb {
  @apply bg-t-black;
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-t-beige;
}
